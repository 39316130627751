/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

// Components
import { getLanguage } from 'services/language'
import BeforeFooter from './BeforeFooter'

// Helpers

const KoelMonteurs = ({ className }) => {
  const language = getLanguage()
  const { componentNL, componentEN } = useStaticQuery(graphql`
    query {
      componentNL : wpComponent(databaseId: {eq: 264 }) {
        ...KoelFrag
      }

      componentEN : wpComponent(databaseId: {eq: 691 }) {
        ...KoelFrag
      }
    }
  `)

  const componentSearch = language === "nl_NL" ? componentNL.componentSearch : componentEN.componentSearch

  return <BeforeFooter className={className} image={componentSearch.image} title={componentSearch.title} cta={componentSearch.cta} />
}

export default KoelMonteurs
